(function(){
    "use strict";
    app.landingForm = {
        /**
         * селекторы модуля
         */
        _selectors: {
            container: '.slide-form-wrapper',
            formWrapper: '.forms-wrapper',
            action_inputs: '.form-inputs',
            action_success_text: '.form-message',
        },
        /**
         * jQuery элементы модуля
         */
        _elements:{},
        /**
         * Флаг инициализации модуля
         */
        _init: false,

        /**
         * Функция нмциализации модуля
         */
        init: function(){
            var $container = $(this._selectors.container);
            if($container.length && !this._init){
                this._init = true;
                this._formCopy();
                this._listener();
                this._toggleActionContent();
                this.slideDownToForm('&downtoform');
            }
        },
        _formCopy: function(){
            var $form_wrapper = $(this._selectors.formWrapper);
            if($form_wrapper.find('form').length){
                $(this._selectors.container).html($form_wrapper.html());
            }
        },
        /**
         * Постановка обраьтчиков событий
         * @private
         */
        _listener: function(){

        },

        /**
         * Прячем либо инпуты, либо надпись об удачном завершении регистрации
         * @private
         */
        _toggleActionContent: function() {
            var $form_inputs =  $(this._selectors.action_inputs);
            var $form_message = $(this._selectors.action_success_text);
            if($form_inputs.length && $form_message.length){
                if(localStorage.getItem('just_registered')) {
                    $form_inputs.addClass('hidden');

                } else {
                    $form_message.addClass('hidden');
                }
            }
        },
        /**
         * Если в адресе по которому перешел пользователь есть флаг то проматываем страницу до формы и убираем флаг из урла
         * @param flag {String} строка которую мы ищем в урле
         */
        slideDownToForm: function(flag) {
            var url = location.toString();
            if(url.indexOf(flag) > -1) {
                var flagless_url = url.replace(flag, '');
                history.pushState({}, '', flagless_url);

                var $container = $(this._selectors.container);
                    var container_top = $container.offset().top;
                    if(container_top) {
                        window.scrollTo(0, container_top - 100);
                    }

            }
        },

        /**
         * Отрабатываем ресайз браузера
         */
        resize: function(){}
    };
})();