(function(){
    app.referral = {
        _storageName: 'referral-data',
        data: {},
        /**
         * Инициализация модуля
         */
        init: function(){
            if(!this._parseReferral()){
                this._read();
                var referrer =  document.referrer;
                if(referrer && referrer.indexOf(location.host) < 0){
                    this.data.referrer = referrer;
                    this._save();
                }
            }else{
                var referrer =  document.referrer;
                if(referrer && referrer.indexOf(location.host) < 0){
                    this.data.referrer = referrer;

                }
                this._save();
            }
        },
        /**
         * парсим реферальную ссылку
         * @private
         */
        _parseReferral: function () {
            var url = location.toString();
            var ref_str = url.match(/ref_guid=[0-9a-zA-Z]+/);
            if(_.isArray(ref_str) && ref_str.length){
                ref_str = ref_str[0];
                ref_str = ref_str.replace('ref_guid=', '');
                this.data = {
                    guid: ref_str,
                    referrer: document.referrer
                };
                return true;
            }
            return false;
        },
        /**
         * Сохроняем данные реферала
         * @private
         */
        _save: function(){
            var str_data = JSON.stringify(this.data || {});
            localStorage.setItem(this._storageName, str_data);
        },
        /**
         * Четаем данные реферала
         * @private
         */
        _read: function(){
            var data = localStorage.getItem(this._storageName);
            if(data){
                try{
                    data = JSON.parse(data);
                }catch (e){
                    console.log(e);
                }
            }
            return _.isObject(data) ? data : {};
        }
    };
})();