var app = app || {};

/*
 app.breakpoints = {
 mobile: 320,
 fablet: 555,
 bigFablet:619,
 tablet: 768,
 bigTablet: 930,
 desktop: 1024,
 laptop: 1240,
 desktopMedium: 1550,
 desktopBig:  1550,
 };
 */
(function () {
    "use strict";

    /**
     *
     * @type {{windowWidth: number, windowHeight: number, breakpointState: number, trueResize: boolean, langGuid: string}}
     */
    app.vars = {
        windowWidth: 0,
        windowHeight: 0,
        breakpointState: 1,
        trueResize: false,
        langGuid: ''
    };
    var resizeTimeout = 0,
        $window;
    var _heightResize = 0;
    var _widthResize = 0;
    var _init = false;
    app.init = function() {
        if(!_init){
            _init = true;
            this.vars.langGuid = $('main').data('langGuid');
            $window = $(window);
            this.modulesInit();
            this.listener();
            this.resize();
            this.elementsInit();
            this._loadState();
            this._phone();

        }
    };

    app.modules = [];
    app.modulesInit = function(){
        for(var module in app){
            if(app.hasOwnProperty(module)){
                if((_.isObject(app[module]) || _.isFunction(app[module])) && _.isFunction(app[module].init)){
                    app.modules.push(app[module]);
                    app[module].init();
                }
            }
        }
    };

    app.listener = function () {
        $window.resize(this.resize.bind(this));
        var $body = $('body');
        $body.onOne('click', '.form-offert', 'lc_main', this._openOffert.bind(this));
        $body.onOne('click', '.relocation', 'lc_main', this._relocation.bind(this));
        // $body.onOne(app.ajaxForm.events.success, '', 'lc_main', this.modulesInit.bind(this));
        $(window).onOne('scroll', '', 'lc_main', this._scroll.bind(this));
        $body.onOne('submit', '.ajax-form', 'lc_main', this.formAjaxSubmit.bind(this));

        /* $body.on('click', ".item-alem", app.utils.clickable);
         $body.on('click', ".main__categories__item", app.utils.clickable);
         $body.on('click', ".main__products__item", app.utils.clickable);*/
    };

    app.formAjaxSubmit = function (e) {
      e.preventDefault();
        app.ajaxSubmit($(e.currentTarget));
    };

    app._scroll = function(e){
        if(this._scroll.timeout){
            clearTimeout(this._scroll.timeout);
        }
        var that = this;
        this._scroll.timeout = setTimeout(function(){
            _.each(that.modules, function(module){
               if(_.isFunction(module.scroll)){
                   module.scroll(e);
               }
            });
        });
    };
    app._relocation = function(e){
        e.preventDefault();
        var url = $(e.currentTarget).data('url');
        if(url){
            location.assign(url);
        }
    };
    app._openOffert = function(e){
        e.preventDefault();
        var link = $(e.currentTarget).attr('href');
        var text = $(e.currentTarget).data('windowTitle');
        if(link){
            var params = '';
            params += 'width=' + Math.ceil(window.screen.width/2) + 'px,';
            params += 'height=' + window.screen.height + 'px,';
            params += 'left=' + Math.ceil(window.screen.width/2) + 'px,';
            params += 'top=' + 0 + 'px';
            var win = window.open(link, text || '', params);
        }
    };
    app.formStaticSubmit = function(e){
        var $form = $(e.currentTarget);
        if(!$form.hasClass('.ajax-form') && !$form.data('valid')){
            e.preventDefault();
            var state =  app.validator.formValidate([], $form);
            $form.data('valid', state);
            if(state){
                $form.submit();
            }
        }
    };
    app._iframeHeight = function () {
      $('iframe').each(function () {
            var $item = $(this);
            var width = $item.outerWidth();
            $item.css('height', width * 0.66);
      });
    };
    app._bodyPadding = function(){
        if(!app._bodyPadding.headerWrap){
            app._bodyPadding.headerWrap = $('.header-wrap');
            app._bodyPadding.main = $('.main');
        }
        var heightHeader = app._bodyPadding.headerWrap.outerHeight();
        if(heightHeader){
            app._bodyPadding.main.css('padding-top', heightHeader);
        }
    };

    app.formSubmit = function(e){
        e.preventDefault();
        var form = $(e.currentTarget);

        form.ajaxSubmit({
            beforeSubmit: function(arr, $form, options){
                var state =  app.validator.formValidate(arr, $form);
                $form.toggleClass('preload', state);
                return state;
            },
            success: function(response, statusText, xhr, form){
                $(form).parent().html(response);
            }
        });

    };
    app.elementsInit = function () {
       /* $("select").select2(
            { minimumResultsForSearch: Infinity }
        );*/

    };

    app._phone = function(){

        $('body').on('focus', 'input.phone, [name="form[phone]"]', function(e){
            var $input = $(e.currentTarget);
            if(!$input.data('validate-event')){
                $input.data('validate-event', true);
                e.currentTarget.addEventListener("input", app.phoneValidate);
            }
        });
    };

    app.phoneValidate = function(e){
        var reg = /([0-9\+\s\)\(\-]+)/g;
        var input = $(e.currentTarget);
        var val = input.val();
        if(val){
            var find = val.match(reg);
            input.data('valid', true);
            if(!find ||  find.length > 1 || find[0].length < val.length){

                val  = input.data('valid-val') || '';
                input.val(val);

            }else{
                input.data('valid-val', val);
                input.data('valid', true);
            }
        }
    };

    app.resize = function () {
        if(resizeTimeout){
            clearTimeout(resizeTimeout);
        }
        if (!app.slider._inFullscreenMode) {
          resizeTimeout = setTimeout(function () {

              this.vars.windowWidth = $window.innerWidth();
              this.vars.windowHeight = $window.outerHeight();
              if(app.vars.windowHeight - _heightResize >= 100 || app.vars.windowHeight - _heightResize <= -100|| _widthResize != app.vars.windowWidth){
                  app.vars.trueResize = true;
              }
              _heightResize = app.vars.windowHeight;
              _widthResize = app.vars.windowWidth;

              this.vars.breakpointState = 1;
              if(this.vars.windowWidth >= 760){
                  this.vars.breakpointState = 2;
              }
              if(this.vars.windowWidth >= 1280){
                  this.vars.breakpointState = 3;
              }
              if(this.vars.windowWidth >= 1520){
                  this.vars.breakpointState = 4;
              }
              for(var module in app){
                  if(app.hasOwnProperty(module)){
                      if((_.isObject(app[module]) || _.isFunction(app[module])) && _.isFunction(app[module].resize)){
                          app[module].resize();
                      }
                  }
              }
              if(app.vars.trueResize ){
                  this.elementsInit();
              }
              app.vars.trueResize = false;
              app._bodyPadding();
              app._iframeHeight();
          }.bind(this), 100);
        }

    };

    /**
     *
     * @returns {boolean}
     */
    app.isMobile = function () {
        return this.vars.breakpointState === 1;
    };
    /**
     *
     * @returns {boolean}
     */
    app.isTablet = function () {
        return this.vars.breakpointState === 2;
    };

    /**
     *
     * @returns {boolean}
     */
    app.isDesktop = function () {
        return this.vars.breakpointState >= 3;
    };
    /**
     *
     * @returns {boolean}
     */
    app.isBigDesktop = function () {
        return this.vars.breakpointState === 4;
    };

    /**
     *
     * Выставляем флаг загрузки приложения
     * @private
     */
    app._loadState = function(){
        $('body').addClass('app-load');
    };

    app.isEdge = function() {
      var ua = window.navigator.userAgent;
      if(ua.indexOf('Edge/') > 0) {
        return true;
      }
    }
})();

/**
 *
 * find DOM elements
 * @param selectors {{}}
 * @param [parent] {*|jQuery|HTMLElement}
 * @returns {{any}}
 */
app.elementsParse = function (selectors, parent) {
    var _parent = $(parent).length ? $(parent) : $("body");
    var _result = {};
    _.each(selectors, function (val, key) {
         var $item = _parent.find(val);
         if($item.length){
           _result[key] = $item;
         }else if(_parent.is(val)){
           _result[key] = _parent;
         }else{
           _result[key] = $item;
         }
    });
    return _result;
};

$(document).ready(function() {
    app.init();
});
