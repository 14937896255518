/**
 * Created by ursus on 08.07.2016.
 */

/* globals _ */
/* globals cms */
(function () {
    'use strict';

    app.validator = {
        validateMessage: '',
        config: {
            selectorFieldItem: '.form-item, .form-item-big',
            classInvalid: 'invalid',
            classValid: 'valid',
        },
        validArray: {},
        /**
         *
         * @param data
         * @param config
         */
        validate: function (data, config) {
            app.validator.parseMessage();
            var validate = true;
            _.each(config, function (rules, name) {
                if(data.hasOwnProperty(name)){
                    var validate = this.parseRules(rules);
                    if(!this.valid(validate, data[name])){
                        validate = false;
                    }
                }
            }.bind(this));
            return validate;
        },
        valid: function(rules, data){
            app.validator.parseMessage();
            var validate = true;
            _.each(rules, function(rule){
                if(!rule(data)){
                    validate = false;
                }
            });
            return validate;
        },
        parseMessage: function(){
            if(!app.validator.validateMessage){
                app.validator.validateMessage = $('.validate-message').data();
            }
        },
        parseRules: function (rulesStr) {
            var rules = rulesStr.trim().split(' ');
            var resultRulles = [];
            _.each(rules, function (rule) {
                resultRulles.push(this.parseRule(rule));
            }.bind(this));
            return resultRulles;
        },
        parseRule: function(rule){
            if(rule.indexOf("(") > -1){
                var _rule = rule.substring(0, rule.indexOf("("));
                var _val = rule.substring(rule.indexOf("(") + 1, rule.indexOf(")") -1);
                if(_.isFunction(this.validateFunction[_rule])){
                    return this.validateFunction[_rule].bind(this, _val);
                }else{
                    console.warn("Функция валидации не найдена - " + _rule);
                    return this.defaultFunction;
                }
            }else{
                if(_.isFunction(this.validateFunction[rule])){
                    return this.validateFunction[rule];
                }else{
                    console.warn("Функция валидации не найдена - " + rule);
                    return this.defaultFunction;
                }
            }
        },
        validateFunction: {
            date: function (val) {
                return _.isDate(val);
            },
            float: function (val) {
                return validator.isFloat(val);
            },
            int: function (val) {
                return validator.isInt(val);
            },
            number: function (val) {
                return validator.isNumeric(val);
            },
            string: function (val) {
                return _.isString(val);
            },
            noEmpty: function (val) {
                return !_.isEmpty(val);
            },
            boolean: function (val) {
                return _.isBoolean(val);
            },
            required: function (val) {
                if(_.isString(val)){
                    val = val.trim();
                }
                return !!val;
            },
            email: function (val) {
                var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                return pattern.test(val);
            },
            phone: function (val){
                return validator.isMobilePhone(val);
            },
            valid: function(val, dataVal){
                return dataVal;
            },
            checked: function (val, dataVal, el) {
                return $(el).prop("checked");
            },
            orRequired: function(val, dataVal, el){
                var result = app.validator.validateFunction.required(val, dataVal, el);
                if(!result){
                    var item = _.findWhere(app.validator.validArray, {name: dataVal});
                    if(item){
                        result = app.validator.validateFunction.required(item.value);
                    }
                }
                return result;
            }
        },
        invalidTextFunction: {
            date: function (val) {
                return app.validator.validateMessage.date;
            },
            float: function (val) {
                return app.validator.validateMessage.float;
            },
            int: function (val) {
                return app.validator.validateMessage.int;
            },
            number: function (val) {
                return app.validator.validateMessage.number;
            },
            string: function (val) {
                return app.validator.validateMessage.string;
            },
            noEmpty: function (val) {
                return app.validator.validateMessage.notEmpty;
            },
            boolean: function (val) {
                return app.validator.validateMessage.boolean;
            },
            required: function (val) {
                return  app.validator.validateMessage.required;
            },
            checked: function (val) {
                return app.validator.validateMessage.checked;
            },
            email: function (val) {
                return  app.validator.validateMessage.email;
            },
            phone: function (val){
                return  app.validator.validateMessage.phone;
            },
            orRequired: function(value, ruleVal, elem)  {
                return $(elem).data('orRequiredMessage');
            }
        },
        defaultFunction: function(){
            return true;
        },
        formValidate: function(array, $form){
            var allValid = true;
            var that = this;
            app.validator.parseMessage();
            that.validArray = [];
            $form.find('input, select, textarea').each(function(){
                var _elem = $(this);
                that.validArray.push({
                    elem: _elem,
                    rules:  _elem.data(),
                    value: _elem.val(),
                    name: _elem.attr('name')
                });
            });
            _.each(that.validArray, function(el){
                var _elemValid = true;
                _.each(el.rules, function (ruleVal, ruleName) {
                    if(_.isFunction(that.validateFunction[ruleName]) && _elemValid){
                          _elemValid = that.validateFunction[ruleName](el.value, ruleVal, el.elem);
                        if(!_elemValid){
                            allValid = false;
                            var text = that.invalidTextFunction[ruleName](el.value, ruleVal, el.elem);
                            el.elem.parents(that.config.selectorFieldItem).addClass(that.config.classInvalid).removeClass(that.config.classValid).attr('data-ivalid-message', text);
                        }else{
                            el.elem.parents(that.config.selectorFieldItem).removeClass(that.config.classInvalid).addClass(that.config.classValid);
                        }
                    }
                });
                if(!_elemValid){

                }
            });
            return allValid;
        }
    };

})();