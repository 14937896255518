(function () {
    "use strict";

    app.slider = {

        _selectors: {
            container: '.container',
            header: '.header',
            nav_wrapper: '.nav-items-wrapper',
            nav_item: '.nav-item',
            slider: '.slider',
            mobile_wrap: '.mobile-only.swiper-wrap',
            tablet_wrap: '.non-mobile.swiper-wrap',
            slide: '.slide',
            bg_video: '.slide-bg-video:not(.gif, .poster-only)',
            all_bg_content: '.slide-bg-video',
            swiper_bg_video: '.swiper-slide-bg-video:not(.gif)',
            swiper_active: '.swiper-slide-active',
            current_slide: '.current-slide',
            dynamic_content: '.dynamic-content'

        },

        _elements: {},

        _init: false,

        slider_height: 0,

        _sliding_in_progress: false,

        _prev_event_ts: 0,

        _inFullscreenMode: false,

        _lastActiveScreenMode: "",

        _lastActiveSlide: {},

        // _hammer: {},

        // Flag for pages w/o main slider, turns off Hammer for tablet on such pages
        _is_simple_page: false,


        init: function () {
            if ($(this._selectors.container).length && !this._init) {
                this._init = true;

                this._elements = app.elementsParse(this._selectors);

                // this._hammer = new Hammer(document.querySelector('body'));
                // this._hammer.get('pan').set({direction: Hammer.DIRECTION_ALL});
                // this._hammer.get('swipe').set({direction: Hammer.DIRECTION_ALL});

                this._hideExtraShadows();
                if(this._elements.slide.length) {
                  this._loadDynamicContent();
                  this._showFirstVideos();
                  this._listeners();

                  // var first_slide_url = this._elements.slide.first().data('url');
                  // this.resetCurrents(first_slide_url);
                  var self = this;
                  this._handleScroll();
                  setTimeout(function(){
                    self._lastActiveScreenMode = app.isMobile() ? 'mobile' : 'tablet';
                  }, 500);
                  setInterval(this.checkForSlideChange.bind(this), 300);
                }

                if(this._elements.container.hasClass('simple-page')) {
                    this._is_simple_page = true;
                }

            }
        },

        _listeners: function () {
            $(window).on('scroll', this._handleScroll.bind(this));
            // this._elements.slider.mousewheel(this._handleMouseSlideChange.bind(this));
            // this._hammer.on("swipedown swipeup", this._handleSwipeSlideChange.bind(this));
            // this._hammer.on("pandown panup ", this._handleSwipeSlideChange.bind(this));
            // $(document).keydown(this._handleKeyboardSlideChange.bind(this));

            $('video').click(this._runBgVideoByClick.bind(this));
            $(this._selectors.bg_video).on('ended', this._handleVideoEnd.bind(this));
        },

        resize: function () {
            if (this._init && app.vars.trueResize) {
                var self = this;
                // if (app.isMobile() || this._is_simple_page) {
                //     this._hammer.get('pan').set({enable: false});
                //     this._hammer.get('swipe').set({enable: false});
                //     this._elements.slide.css("transform", "translateY(0)");
                // } else {
                //   this._hammer.get('pan').set({enable: true});
                //   this._hammer.get('swipe').set({enable: true});
                // }
                var current_slide_index = this._elements.slide.index($(this._selectors.current_slide));
                this._setSlidesHeight();
                this.slider_height = this._elements.slider.innerHeight();
                // if(!app.isMobile()) {
                //   var translate_statement = "translateY" + "(" + (-this.slider_height * (current_slide_index) + "px)");
                //   this._elements.slide.css("transform", translate_statement);
                // }

                // if((app.vars.windowWidth >= 760) && this._lastActiveScreenMode === "mobile") {
                //   if(current_slide_index) {
                //     var translate_statement = "translateY" + "(" + (-app.slider.slider_height * (current_slide_index) + "px)");
                //     $('.slide').css('transform', translate_statement);
                //   } else {
                //     $('.slide').css("transform", "translateY(0)");
                //   }
                // }
            }
        },

        /**
         * _loadDynamicContent - load extra content from cms and add it to page
         *
         * @return {type}  description
         */
        _loadDynamicContent: function() {
          var $content = this._elements.dynamic_content;
          if($content.length) {
            $content.each(function() {
              var self = this;
              var url = $(this).data('url');
              $.get(url, function(data) {
                $(self).html(data);
              });
            });
          }
        },
        /**
         * _runBgVideo - run video manually if it didn't run for some reason
         *
         * @param  {type} e description
         * @return {type}   description
         */
        _runBgVideoByClick: function(e) {
          e.preventDefault();
          var video = e.currentTarget;

          if ($(video).hasClass('swiper-slide-bg-video')) {
            var $swiper_slide = $(video).closest('.swiper-slide');
            if($swiper_slide.hasClass('swiper-slide-active')) {
              if(video.paused) {
                video.currentTime = 0;
                this._handlePromise(video);
              } else {
                if(document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement) {
                  this._closeFullscreen();
                } else {
                  this._runFullscreen(video);
                }
              }
            } else if(document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement) { // in case swiper slide changed accidentalluy while video in fullscreen
              this._closeFullscreen();
            }
          } else {
            if(video.paused) {
              video.currentTime = 0;
              this._handlePromise(video);
            }
          }


        },

        _runFullscreen: function(video) {
          this._elements.slide.addClass('no-transition');
          this._inFullscreenMode = true;
          if(video.requestFullScreen) {
            video.requestFullScreen();
            $(video).addClass('no-transform');
          } else if(video.webkitRequestFullScreen ) {
            video.webkitRequestFullScreen();
            $(video).addClass('no-transform');
          } else if(video.mozRequestFullScreen) {
            video.mozRequestFullScreen();
            $(video).addClass('no-transform');
          }
          video.currentTime = 0;
          this._handlePromise(video);
        },

        _closeFullscreen: function() {
          if(document.exitFullscreen) {
            document.exitFullscreen();
          } else if(document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if(document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
          }
          var self = this;
          setTimeout(function() {
            self._inFullscreenMode = false;
            self._elements.slide.removeClass('no-transition');
            self._elements.swiper_bg_video.removeClass('no-transform');
          }, 100);

        },
        // /**
        //  * _setSlidesHeight - for desktop or tablet version removes extra space
        //  */
        _setSlidesHeight: function () {
            var that = this;
            if (app.vars.breakpointState >= 2) {
                // var header_height = this._elements.header.outerHeight();
                // if(this._elements.slider.length) {
                //
                //     var $slides = this._elements.slider.find(this._selectors.slide);
                //     $slides.each(function(index, slide) {
                //
                //         var $slide = $(slide);
                //         var $bg = $slide.find(that._selectors.all_bg_content);
                //         if($bg.length) {
                //             var slide_height = $slide.outerHeight();
                //
                //             if (slide_height >= (app.vars.windowWidth * 0.5625)) {
                //                 $bg.css('height', slide_height + 20);
                //                 $bg.css('width', "auto");
                //             } else {
                //                 $bg.css('width', (app.vars.windowWidth + 10));
                //                 $bg.css('height', "auto");
                //             }
                //         }
                //
                //     });
                // } else
                    if (this._elements.all_bg_content.length){
                    this._elements.all_bg_content.each(function() {
                        var $bg = $(this);
                        var $container = $bg.parent();
                        var container_height = $container.outerHeight();

                        if (container_height >= (app.vars.windowWidth * 0.5625)) {
                            $bg.css('height', container_height + 20);
                            $bg.css('width', "auto");
                        } else {
                            $bg.css('width', (app.vars.windowWidth + 10));
                            $bg.css('height', "auto");
                        }
                    })
                }

              // var new_slider_height = app.vars.windowHeight - header_height;
              //
              //   // this._elements.slider.css('height', new_slider_height);
              //   if (new_slider_height >= (app.vars.windowWidth * 0.5625)) {
              //       this._elements.all_bg_content.css('height', new_slider_height + 20);
              //       this._elements.all_bg_content.css('width', "auto");
              //   } else {
              //       this._elements.all_bg_content.css('width', (app.vars.windowWidth + 10));
              //       this._elements.all_bg_content.css('height', "auto");
              //   }

            } else { // reset for mobile version
                this._elements.all_bg_content.css('width', "");
                this._elements.all_bg_content.css('height', "");
            }
        },


        /**
         * _handleMobileScroll - on scroll on mobile devices look for closest slide and run its background video
         *
         */
        _handleScroll: function () {
            // if (app.isMobile() && ($(window).innerWidth() < 760) && !app.header._moved_by_nav_menu) {
            //     var self = this;
            //
            //     var scroll_pos = $(window).scrollTop() + 62; // include header height;
            //     this._elements.slide.each(function () {
            //         var slide_height = $(this).height();
            //         var slide_top = $(this).offset().top;
            //         if (scroll_pos >= slide_top - slide_height / 4 && scroll_pos <= slide_top + slide_height / 2) {
            //             var slide_url = $(this).data('url');
            //             self.resetCurrents(slide_url);
            //         }
            //     });
            // }
            if (!app.header._moved_by_nav_menu) {
                var self = this;

                var scroll_pos = $(window).scrollTop() + 62; // include header height;
                this._elements.slide.each(function () {
                    var slide_height = $(this).height();
                    var slide_top = $(this).offset().top;
                    if (scroll_pos >= slide_top - slide_height / 4 && scroll_pos <= slide_top + slide_height / 2) {
                        var slide_url = $(this).data('url');
                        self.resetCurrents(slide_url);
                    }
                });
            }
        },

        /**
         * _handleMouseSlideChange - scroll rules on trackpad and mousewheel
         *
         * @param  {Event} e     description
         * @param  {type} delta mousewheel direction
         */
        // _handleMouseSlideChange: function (e, delta) {
        //     if(this._prev_event_ts === 0) {
        //       this._prev_event_ts = e.timeStamp;
        //     } else {
        //       if(e.timeStamp - this._prev_event_ts > 50) {
        //         this._prev_event_ts = e.timeStamp;
        //       } else {
        //         this._prev_event_ts = e.timeStamp;
        //         return;
        //       }
        //     }
        //     if (!app.isMobile()) {
        //         var slide_order = $(this._elements.slide).index($(this._selectors.current_slide));
        //         var slides_quantity = this._elements.slide.length;
        //         var new_slide_url;
        //         if (delta < 0 && slide_order < (slides_quantity - 1)) {
        //             new_slide_url = this._elements.slide.eq(slide_order + 1).data('url');
        //             this._getNewSlide(new_slide_url);
        //         } else if (delta > 0 && slide_order > 0) {
        //             new_slide_url = this._elements.slide.eq(slide_order - 1).data('url');
        //             this._getNewSlide(new_slide_url);
        //         }
        //     }
        // },

        /**
         * _handleSwipeSlideChange function - scroll rules for swiping moves
         *
         * @param  {type} e description
         * @return {type}   description
         */
        // _handleSwipeSlideChange: function (e) {
        //
        //     e.preventDefault();
        //     if (!app.isMobile()) {
        //       var slide_order = $(this._elements.slide).index($(this._selectors.current_slide));
        //       var slides_quantity = this._elements.slide.length;
        //       var new_slide_url;
        //         if (e.type === "swipeup" || (e.type === 'panup' && e.distance > 100)) {
        //             if (slide_order < (slides_quantity - 1)) {
        //               new_slide_url = this._elements.slide.eq(slide_order + 1).data('url');
        //               this._getNewSlide(new_slide_url);
        //             }
        //         } else if (e.type === "swipedown" || (e.type === "pandown" && e.distance > 100)) {
        //             if (slide_order > 0) {
        //               new_slide_url = this._elements.slide.eq(slide_order - 1).data('url');
        //               this._getNewSlide(new_slide_url);
        //             }
        //         }
        //     }
        // },
        //
        // _handleKeyboardSlideChange: function (e) {
        //   if (!app.isMobile()) {
        //     var slide_order = $(this._elements.slide).index($(this._selectors.current_slide));
        //     var slides_quantity = this._elements.slide.length;
        //     var new_slide_url;
        //       if (e.which === 40) {
        //           if (slide_order < (slides_quantity - 1)) {
        //             new_slide_url = this._elements.slide.eq(slide_order + 1).data('url');
        //             this._getNewSlide(new_slide_url);
        //           }
        //       } else if (e.which === 38) {
        //           if (slide_order > 0) {
        //             new_slide_url = this._elements.slide.eq(slide_order - 1).data('url');
        //             this._getNewSlide(new_slide_url);
        //           }
        //       }
        //   }
        // },

        /**
         * _handleVideoEnd - if there is more than 1 background video on slide, run next one;
         *                   if loop flag is active, once playlist ends, start over again.
         */
        _handleVideoEnd: function(e) {
            e.preventDefault();
            var video = e.currentTarget;
            var $current_slide = $(this._selectors.current_slide);
            var $videos = $current_slide.find(this._selectors.bg_video);
            var index = $videos.index($(video));
            if(index === -1) {return};
            if($videos.length > 1) {
              if($videos.length > (index + 1)) {
                $videos.addClass('hidden');
                var next_video = $videos.get(index+1);
                $(next_video).removeClass('hidden');
                next_video.currentTime = 0;
                this._handlePromise(next_video);
              } else {
                $videos.addClass('hidden');
                var first_video = $videos.get(0);
                $(first_video).removeClass('hidden');
                first_video.currentTime = 0;
                if($current_slide.hasClass('bg-looped')) {
                  this._handlePromise(first_video);
                }
              }
            } else if($current_slide.hasClass('bg-looped')) {
              video.currentTime = 0;
              this._handlePromise(video);
            }
        },

        // _getNewSlide: function (slide_url) {
        //     if (!this._sliding_in_progress) {
        //         this._sliding_in_progress = true;
        //
        //         var $new_slide = this._elements.slider.find("[data-url='" + slide_url + "']");
        //         var new_slide_order = this._elements.slide.index($new_slide);
        //
        //         var translate_statement = "translateY" + "(" + (-this.slider_height * new_slide_order) + "px)";
        //         this._elements.slide.css("transform", translate_statement);
        //
        //         this.resetCurrents(slide_url);
        //         setTimeout(this._clearSliding.bind(this), 750);
        //     }
        // },

        /**
         * checkForSlideChange - check if slide changed
         *
         */
        checkForSlideChange: function() {
          var $current_slide;
          this.resetFullscreenFlag();
          if($('.current-slide').hasClass('gallery-slide')){
            if(app.isMobile()) {
              $current_slide = $('.current-slide').find(this._selectors.mobile_wrap).find(this._selectors.swiper_active);
              if(this._lastActiveScreenMode === 'tablet') {
                this._stopVideos('tablet');
                var video = $current_slide.find(this._selectors.swiper_bg_video).get(0)
                this._handlePromise(video);
                this._lastActiveScreenMode = 'mobile';
                this._lastActiveSlide = $current_slide;
              } else {
                if($(this._lastActiveSlide).is($current_slide)) {
                  return;
                } else {
                  this._lastActiveSlide = $current_slide;
                  this._stopVideos('mobile');
                    var $swiper_bg_video = $current_slide.find(this._selectors.swiper_bg_video);
                    if($swiper_bg_video.length) {
                        var swiper_bg_video = $swiper_bg_video.get(0);
                        swiper_bg_video.currentTime = 0;
                        this._handlePromise(swiper_bg_video);
                    }
                }
              }
            }
            if(!app.isMobile()) {
              $current_slide = $('.current-slide').find(this._selectors.tablet_wrap).find(this._selectors.swiper_active);
              if(this._lastActiveScreenMode === 'mobile') {
                this._stopVideos('mobile');
                var video = $current_slide.find(this._selectors.swiper_bg_video).get(0);
                this._handlePromise(video);
                this._lastActiveScreenMode = 'tablet';
                this._lastActiveSlide = $current_slide;
              } else {
                if($(this._lastActiveSlide).is($current_slide)) {
                  return;
                } else {
                  this._lastActiveSlide = $current_slide;
                  this._stopVideos('tablet');
                  var $swiper_bg_video = $current_slide.find(this._selectors.swiper_bg_video);
                    if($swiper_bg_video.length) {
                        var swiper_bg_video = $swiper_bg_video.get(0);
                        swiper_bg_video.currentTime = 0;
                        this._handlePromise(swiper_bg_video);
                    }
                }
              }
            }
          } else {
            $current_slide = $(this._selectors.current_slide);
            if(!($(this._lastActiveSlide).is($current_slide))) {
              this._lastActiveSlide = $current_slide;
              if(app.isMobile()) {
                this._stopVideos('mobile');
              } else {
                this._stopVideos('tablet');
              }
              var $bg_videos = $current_slide.find(this._selectors.bg_video);
                if($bg_videos.length) {
                    var first_video = $bg_videos.get(0);
                    $bg_videos.addClass('hidden');
                    $bg_videos.first().removeClass('hidden');

                    first_video.currentTime = 0;
                    this._handlePromise(first_video);
                }

            }
          }
        },

        /**
         * _stopVideos - stop videos in slides for specific swiper
         *
         * @param  {String} mode either 'mobile' or 'tablet'
         */
        _stopVideos: function(mode) {
          var videos;
          var $videos = this._elements.bg_video;
          if(mode === 'mobile') {
            videos = this._elements.mobile_wrap.find(this._selectors.swiper_bg_video);
          }
          if(mode === 'tablet') {
            videos = this._elements.tablet_wrap.find(this._selectors.swiper_bg_video);
          }
          if(videos) {
            videos.each(function(){
                $(this).get(0).pause();
            });
          }
          $videos.each(function () {
                this.pause();
          });
        },

        /**
         * resetCurrents - set new current slide and nav
         *
         * @param  {String} url // if none is passed then set first slide and nav as current

         */
        resetCurrents: function(url) {
          if(url){
            this._elements.slide.removeClass('current-slide');
            this._elements.nav_item.removeClass('current-nav');

            this._elements.nav_wrapper.find("[data-url='" + url + "']").addClass('current-nav');
            var $new_slide = this._elements.slider.find("[data-url='" + url + "']");
            $new_slide.addClass('current-slide');
          }
        },
        /**
         * _clearSliding - allow sliding by resetting flag
         *
         */
        _clearSliding: function () {
          this._sliding_in_progress = false;
        },

        resetFullscreenFlag: function () {
          if (!(document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement) && this._inFullscreenMode) {
            this._inFullscreenMode = false;
            this._elements.slide.removeClass('no-transition');
            this._elements.swiper_bg_video.removeClass('no-transform');
          }
        },

        /**
         * _hideExtraShadows - hide box-shadows between titles on initialization
         *
         */
        _hideExtraShadows: function() {
          var self = this;
          this._elements.slide.each(function() {
            var $big_title = $(this).find('.bigger-title');
            var $small_title = $(this).find('.smaller-title');
            if($big_title.length && $small_title.length) {
              if($big_title.outerWidth() >= $small_title.outerWidth()) {
                $small_title.addClass('bottom-shadow-hidden');
              } else {
                $big_title.addClass('top-shadow-hidden');
              }
            }

          });
        },

        _handlePromise: function(video) {
            var promise = video.play();
            if(promise instanceof Promise){
              promise.catch(function(e){
              });
            }
        },

        _showFirstVideos: function() {
          var self = this;
          this._elements.slide.each(function() {
            var $videos = $(this).find(self._selectors.bg_video);
            if ($videos.length) {
              $videos.first().removeClass('hidden');
            }
          });
        }
    };
})();
