(function(){
	"use strict";

	app.header = {

	_selectors: {
		container: '.header',
    dropdownWrap: '.header-nav-menu-wrap',
    dropdownContent: '.header-nav-menu',
    toggleButton: '.mobile-menu-button',
		overlay: '.overlay',
		current_slide: '.current-slide',
		slider: '.slider',
		slide: '.slide',
		logo: '.logo',
		nav_item: '.nav-item',
	},

	_elements: {},

	_open: false,

	_moved_by_nav_menu: false,

	_init: false,

	init: function(){
		if($(this._selectors.container).length){
		this._init = true;
		this._generateLinks();
		this._elements = app.elementsParse(this._selectors);
		this._elements.dropdownContent.scrollLock(); // prevent parent element from scrolling when reaching child's border



		this._listeners();

		}
	},

	_listeners: function(){
		this._elements.nav_item.on('click', this._navigate.bind(this));
		this._elements.logo.on('click', this._goToStart.bind(this));
    this._elements.toggleButton.click(this.toggleDropdown.bind(this));
    this._elements.overlay.click(this.toggleDropdown.bind(this, false));
	},

	resize: function(){
		if(this._init){
      if(!app.isMobile()) {
        this.toggleDropdown({}, false);
      } else {
				this._setDropdownMaxHeight();
			}
		}
	},

	/**
	 * _generateLinks - for each slide generate link and push them into header
	 *
	 */
	_generateLinks: function() {
		if($('.slide').length) {
			var links = "";
			var self = this;
			$('.slide').each(function(){
				var slide_title = $(this).data('title');
				var slide_url = $(this).data('url');
				var new_link = '<a class="nav-item" data-url="' + slide_url + '">' + slide_title + '</a>';
				links += new_link;
			});

            // TODO временное решение для добавления блога в шапку
			var blog_link = '<a class="blog-link" href="/blog">Blog</a>'
            links += blog_link;
			$('.nav-items-wrapper').html(links);
		}
	},

  /**
   * toggleDropdown - open navigation menu on mobile
   *
   * @param  {Event} e
   * @param  {Boolean} state
   */
  toggleDropdown: function(e, state) {
    if(e && e.preventDefault){
      e.preventDefault();
   }
    var $wrapper = this._elements.dropdownWrap;
    var $content = this._elements.dropdownContent;
    var $button = this._elements.toggleButton;
		var $header = this._elements.container;
    var wrapHeight = $wrapper.outerHeight();
    if((!_.isBoolean(state) && wrapHeight === 0) || (_.isBoolean(state) && state)){
        var contentHeight = $content.outerHeight();
        $wrapper.css('height', contentHeight);
        $wrapper.addClass('open');
        $button.addClass('open');
				$header.addClass('menu-open');
        this._open = true;
    }else if((!_.isBoolean(state) && wrapHeight > 0) || (_.isBoolean(state) && !state)){
        $wrapper.css('height', '');
        $wrapper.removeClass('open');
        $button.removeClass('open');
				$header.removeClass('menu-open');
        this._open = this._elements.dropdownWrap.filter('.open').length ? true : false;
    }
  },

	_setDropdownMaxHeight: function() {
		var height = app.vars.windowHeight - 62;
		this._elements.dropdownContent.css('max-height', height + "px");
		this._elements.dropdownWrap.css('max-height', height + "px");
	},

	/**
	 * _navigate - go to slide by click on nav-menu item
	 *
	 * @param  {Event} e description

	 */
	_navigate: function (e) {
			var self = this;
			e.preventDefault();
			var item = e.currentTarget;
			// var slide_number = $(item).data('number');
			// var slide_class = ".slide-" + slide_number;
			// var current_slide_number = $(this._selectors.current_slide).data('number');
			var url = $(item).data('url');


			var $slide = this._elements.slider.find("[data-url='" + url + "']");
			var slide_index = this._elements.slide.index($slide);
			if(slide_index >= 0){
				// if (!app.isMobile()) {
				// 		var translate_statement = "translateY" + "(" + (-app.slider.slider_height * slide_index) + "px)";
				// 		this._elements.slide.css("transform", translate_statement);
				// } else {
						this._moved_by_nav_menu = true;
						// var $slide = $(slide_class);
						var top_position = $slide.offset().top;
						$('html, body').animate({
								scrollTop: (top_position - this._elements.container.height())
						}, 250);
						this.toggleDropdown({}, false);
						setTimeout(function () {
								self._moved_by_nav_menu = false;
						}, 2000);
				// }

				app.slider.resetCurrents(url);
			}

			// this._elements.nav_item.removeClass('current-nav');
			// this._elements.slide.removeClass('current-slide');
			//
			// $(item).addClass('current-nav');
			// $(slide_class).addClass('current-slide');
	},

	/**
	 * _goToStart - go to first slide/top of scroll
	 *
	 */
	_goToStart: function () {
			var first_slide_url = this._elements.slide.first().data('url');
			if (app.isMobile()) {
					$('html, body').animate({
							scrollTop: 0
					}, 250);
			} else {
					this._elements.slide.css('transform', 'translateY(0)');
			}

			app.slider.resetCurrents(first_slide_url);
	},
	};
})();
