(function(){
    "use strict";
    app.acount = {
        /**
         * селекторы модуля
         */
        _selectors: {
            container: 'body',
            form: '.account-form',
            shareData: '[data-share]',
            formContent: '.form-content'
        },

        /**
         * jQuery элементы модуля
         */
        _elements:{},
        /**
         * Флаг инициализации модуля
         */
        _init: false,
        /**
         * Функция нмциализации модуля
         */
        init: function(){
            var $container = $(this._selectors.container);
            if($container.length){
                this._init = true;
                this._elements = app.elementsParse(this._selectors, $container);
                this._accountConfirm();
                this._listener();

            }
        },
        _shareData: function(){
            var data = $(this._selectors.shareData).data('share');
            data = data || {};
            data.text = $(this._selectors.shareData).html();
            return data;
        },
        /**
         * Постановка обраьтчиков событий
         * @private
         */
        _listener: function(){
            var $body = $('body');
            $body.on('submit', this._selectors.form, this._accountCreate.bind(this));
        },
        /**
         * Создаеи аккаунт
         * @param e
         * @private
         */
        _accountCreate: function(e){
            e.preventDefault();

            var $form = $(e.currentTarget);
            var values = $form.serializeArray();
            var state = app.validator.formValidate(values, $form);
            if(state) {
                var sendObject = {};
                var that = this;
                _.each(values, function (item) {
                    sendObject[item.name] = item.value;
                });

                var properties = {

                    subscription_url: {
                        value: location.protocol + '//' + location.hostname + location.pathname
                    }
                };
                if(app.referral.data && app.referral.data.referrer){
                    properties.referrer = {
                        value: app.referral.data.referrer,
                    };
                }
                if(app.referral.data && app.referral.data.guid){
                    properties.ref_guid = {
                        value: app.referral.data.guid,
                    };
                }
                if(sendObject.action){
                    properties.action = {
                        value: 1,
                    };
                }
                if(sendObject.city){
                    properties.region = {
                        value: sendObject.city
                    }
                }

                $.extend(sendObject, {api: 'account/create', lang_guid: app.vars.langGuid, properties: properties});

                $.post(app.vars.homeUrl, sendObject).done(function (html) {
                    if(_.isObject(html) && html.status == 'error' && html.response && html.response.code == 4000){
                        var $label = $form.find('[name=login]').parents('.form-item');
                        $label.attr('data-ivalid-message', $label.data('accauntError'));
                        $label.addClass('invalid');
                        return;
                    }else{
                        var $action_flag = $form.find('[name=action]');
                        if($action_flag.length) {
                            var ref_guid = $(html).find('[name=ref_guid]').val();
                            if(ref_guid) {
                                var url = location.protocol + '//' + location.hostname + location.pathname + '?ref_guid=' + ref_guid;
                                localStorage.setItem('just_registered', true);
                                location.assign(url);
                            }
                        } else {
                            $form.find(that._selectors.formContent).html(html);
                        }

                    }

                });
            }
        },

        /**
         * Подтверждаем созданный ранее аккаунт
         * @private
         */
        _accountConfirm: function() {
            var url = location.toString();
            var token_str = url.match(/hash=[$\W0-9a-zA-Z]+[^&]/);

            if(_.isArray(token_str) && token_str.length){
                token_str = token_str[0];
                var token = token_str.replace('hash=', '');

                var sendObject = $.extend({}, {api: 'account1/confirm', lang_guid: app.vars.langGuid, hash: token});
                $.post(app.vars.homeUrl, sendObject).done(function(response){
                    history.pushState({}, '', url.replace('&' + token_str, ''));
                    localStorage.removeItem('just_registered');
                    // if(!(_.isObject(response) && response.status === 'error')){
                    //
                    // }
                });
            }
        },
        /**
         * Отрабатываем ресайз браузера
         */
        resize: function(){}
    };
})();