(function(){
    "use strict";

    var apis = {};

    app.saveProperties = function(api_group, properties, endcallback){
        apis[api_group] = {
            callback: endcallback,
            properties: []
        };
        _.each(properties, function (value, property) {
            var params;
            if(_.isString(value)) {
                params = {
                    value: value
                };
            }else{
                params = value;
            }
            params.lang_guid = null;
            params.api = api_group + '/' + property;

            apis[api_group].properties.push( {
               url: '/',
               params: params
           });
        });
        app.saveProperties.send(api_group);

    };
    app.saveProperties.send = function(api_group){
        if(apis[api_group].properties.length) {
            var send_data = apis[api_group].properties.pop();
            $.post(send_data.url, send_data.params).done(function () {
                app.saveProperties.send(api_group);
            }).fail(function(){
                app.saveProperties.send(api_group);
            });
        }else if(_.isFunction(apis[api_group].callback)){
            apis[api_group].callback();
        }
    };

})();