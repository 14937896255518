(function(){
    "use strict";
    app.findSubscribtion = {
        /**
         * селекторы модуля
         */
        _selectors: {
            container: '.find-subscribtion-form',
            login: '[name="login"]',
            resultContainer: '.find-subscribtion-form-result',
            resultTitle: '.find-subscribtion-form-result-title',
            resultContent:'.find-subscribtion-form-result-content'
        },
        /**
         * jQuery элементы модуля
         */
        _elements:{},
        /**
         * Флаг инициализации модуля
         */
        _init: false,
        /**
         * Функция нмциализации модуля
         */
        init: function(){
            var $container = $(this._selectors.container);
            if($container.length){
                this._init = true;
                this._listener();
            }
        },
        /**
         * Постановка обраьтчиков событий
         * @private
         */
        _listener: function(){
            var $body = $('body');
            $body.on('submit', this._selectors.container, this._findAccount.bind(this));
        },
        /**
         *
         * @param {Event} e
         * @private
         */
        _findAccount: function(e){
            e.preventDefault();
            var $form = $(e.currentTarget);
            var $login = $form.find(this._selectors.login);
            var email = $login.val();
            var that = this;
             var arr = $form.serializeArray();
            var state = app.validator.formValidate(arr, $form);
            if(state){
                $.post('/', {api: 'account/find', lang_guid: app.vars.langGuid, login: email}).done(function(data){
                    if(data && _.isString(data)){
                        try{
                            data = JSON.parse(data);
                        } catch (e){
                            console.log(e);
                        }
                    }
                    if(data && _.isObject(data) && data.guid){
                        var state_cred = false;
                        _.each(data.credentials, function (cred) {
                            if(!state_cred) {
                                state_cred = cred.state;
                            }
                        });
                        if(state_cred == "1") {
                            that._getSubscription(data.guid, $form);
                        }else{
                            var $label = $login.parents('.form-item');
                            $label.attr('data-ivalid-message', $label.data('notActivateMessage'));
                            $label.addClass('invalid');

                        }
                    }else {
                        $form.find(that._selectors.resultTitle).html('Account not found');
                        $form.find(that._selectors.resultContent).html('');
                        $form.find(that._selectors.resultContainer).addClass('show');
                    }

                });
            }

        },
        _tplItem: _.template('<div class="result-subscription-item">' +
            '<span class="result-subscription-item-date"><%=date%></span>' +
            '<span class="result-subscription-item-count"><%=count%> followers</span>' +
            '</div>'),
        _getSubscription: function (account_guid, $form) {
            var that = this;
            $.post('/', {api:'subscription/find', lang_guid: app.vars.langGuid, 'filters[ref_guid]': account_guid }).done(function (data) {
                if(data && _.isString(data)){
                    try{
                        data = JSON.parse(data);
                    } catch (e){
                        console.log(e);
                    }
                }
                if(data && _.isObject(data)){
                    if(_.isEmpty(data.elements)){
                        $form.find(that._selectors.resultTitle).html('No result');
                        $form.find(that._selectors.resultContent).html('');
                        $form.find(that._selectors.resultContainer).addClass('show');
                    }else{
                        $form.find(that._selectors.resultTitle).html(data.pager.elements + ' result');
                        var result = that._elementsHtml(data.elements);
                        $form.find(that._selectors.resultContent).html(result);
                        $form.find(that._selectors.resultContainer).addClass('show');
                    }
                }
            });
        },
        _elementsHtml: function(elements){
            var result = '';
            var dates = {};
            var that = this;
            _.each(elements, function(elem){
               var date = new Date(elem._created * 1000);
               var month = date.getMonth() + 1;
               month = month > 9 ? month : '0' + month;
               var day = date.getDate();
               day = day > 9 ? day : '0' + day;
               var date_str = day + '.' + month + '.' + date.getFullYear();
               if(!dates[date_str]){
                   dates[date_str] = {
                        date: date_str,
                       count: 1
                   };
               }else{
                   dates[date_str].count++;
               }
            });

            _.each(dates, function(date){
                result += that._tplItem(date);
            });
            return result;
        },
        /**
         * Отрабатываем ресайз браузера
         */
        resize: function(){}
    };
})();