(function(){
	"use strict";

	app.buy_popup = {

	_selectors: {
    container: 'body',
    buy_button: '.buy-button',
    buy_popup: '.buy-popup',
		contact_button: '.contact-us',
    buy_form: '.buy-form'
	},

	_elements: {},

	_init: false,

	init: function(){
		if($(this._selectors.container).length){
		this._init = true;
		this._elements = app.elementsParse(this._selectors);
		this._listeners();
		}
	},

	_listeners: function(){
    this._elements.buy_button.click(this._openPreorderPopup.bind(this));
		  this._elements.contact_button.click(this._openPreorderPopup.bind(this));
    // this._elements.container.on('submit', this._selectors.buy_form, this._sendOrder.bind(this));
	},

	resize: function(){
		if(this._init){

		}
	},

  /**
   *
   * _openPreorderPopup - open popup on button click
   *	@param  {Event} e
   */
  _openPreorderPopup: function(e) {
    e.preventDefault();
    var $popup = $('footer').find(this._elements.buy_popup).clone();
    $popup.css('display', 'block');

    app.popups.popupOpen($popup);
    $('.mfp-content').find('textarea').niceScroll({
      horizrailenabled: false
    });
    if(app.isMobile()) {
      app.header.toggleDropdown({}, false);
    }

  },

  /**
   * _sendOrder - handle form submit
   *
   * @param  {Event} e description
   */
  // _sendOrder: function(e) {
  //   e.preventDefault();
  //   var $form = $(e.currentTarget);
  //   var self = this;
  //   // Form submit goes here...
	//
  //   app.popups.popupsClose();
  // }
	};
})();
