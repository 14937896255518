/**
 * Created by ursus on 11.07.2016.
 */

/* globals _ */
/* globals cms */

(function () {
    "use strict";

    app.popups = {
        _selectors: {
            popupRecallOpen: '.popup-open-recall',
            popupQuestionOpen: '.popup-open-question',
            close: '.popup-close',
            popupRecall: '.form-recall',
            popupQuestion: '.form-question',
        },
        _namespace: 'app_popups',
        init: function () {
            this._listener();
        },
        _listener: function () {
            var $body = $('body');
            $body.onOne('click', this._selectors.popupQuestionOpen, this._namespace, this._questionPopupOpen.bind(this));
            $body.onOne('click', this._selectors.popupRecallOpen, this._namespace, this._recallPopupOpen.bind(this));
            $body.onOne('click', this._selectors.close, this._namespace, this._popupsClose.bind(this));
        },
        _recallPopupOpen: function (e) {
            var $el = $(e.currentTarget);
            if ($el.hasClass('desktop') && !app.isDesktop()) {
                if($el.is('a')){
                    location.assign($el.attr('href'));
                }
                return;
            }
            e.preventDefault();
            var form = $(this._selectors.popupRecall).clone();
            if (form.length) {
                this.popupOpen(form);
            }
        },
        _questionPopupOpen: function (e) {
            e.preventDefault();
            var form = $(this._selectors.popupQuestion).clone();
            if (form.length) {
                this.popupOpen(form);
            }
        },
        popupsClose: function () {
          this._popupsClose();
        },
        _popupsClose: function () {
            $.magnificPopup.close();
        },
        popupOpen: function (el) {
            $.magnificPopup.open({
                items: {
                    src: el,
                    type: 'inline',
                    midClick: true,
                    closeBtnInside: true
                }
            });
        },

    };
})();