(function () {
    "use strict";
    app.swiper_initializer = {

      swipers: [],
      init: function() {
        var self = this;
        $('.gallery-slide').each(function() {

          var swiper = new app.mini_swiper($(this));
          self.swipers.push(swiper);

        });
      },
      resize: function() {
        this.swipers.forEach(function(swiper) {
          swiper.resize();
        });
      }
    };

    app.mini_swiper = function(parent){
      this._elements = {};
      this.mobile_swiper = {};
      this.tablet_swiper = {};
      this._slide_width = 0;
      this._config = { initial_slide: 0};
      this._init = false;
      this.initModule(parent);
    };
    app.mini_swiper.prototype = {

        _selectors: {
            mobile_wrap: '.mobile-only.swiper-wrap',
            tablet_wrap: '.non-mobile.swiper-wrap',
            container: '.swiper-container',
            swiper: '.swiper-wrapper',
            slide: '.swiper-slide',
            video: '.swiper-slide-bg-video',
            current_slide: '.swiper-slide-active',
            video_wrapper: '.video-wrapper',
            padding: '.padding-block'
        },

        initModule: function (parent) {
            if ($(this._selectors.tablet_wrap).length) {
                this._init = true;
                this._elements = app.elementsParse(this._selectors, parent);

                var num_of_videos = this._elements.tablet_wrap.find(this._selectors.video).length;
                this._config.initial_slide = Math.floor(num_of_videos/2);

                this._inspectionSliderInit();
                this._listeners();
                var self = this;
                // setTimeout(function () {
                //     self._elements.tablet_wrap.css('visibility', 'visible');
                //     self._elements.mobile_wrap.css('visibility', 'visible');
                //
                // }, 500); // prevents visual glitch invoked by swiper placing it's slides


            }
        },

        _listeners: function () {
          this._elements.tablet_wrap.find(this._selectors.slide).on('class-change', this._handleClassChange.bind(this));
        },

        resize: function () {
          var self = this;
          if (this._init) {
            setTimeout(function(){
              self._setSlidesHeight();
            }, 200);

            if(app.isMobile()){
        			this._setVideoWrapperWidth();
        	  }
          }
        },

        _inspectionSliderInit: function () {
          	var tablet_container = this._elements.tablet_wrap.find(this._selectors.container);
            var mobile_container = this._elements.mobile_wrap.find(this._selectors.container);
            this.mobile_swiper = new Swiper(mobile_container, {
              slidesPerView: 'auto',
              centeredSlides: true,
              paginationClickable: true,
              nextButton: '.swiper-button-next',
              prevButton: '.swiper-button-prev',
      				grabCursor: true,
              spaceBetween: 280,
            });
            if(!app.isEdge()){
              this.tablet_swiper = new Swiper(tablet_container, {
                  // initialSlide: this._config.initial_slide,
                   initialSlide: 0,
                   slidesPerView: 'auto',
                   centeredSlides: true,
                  slideToClickedSlide: true,
                  grabCursor: true,
                  spaceBetween: -100,
                  effect: 'coverflow',
                  coverflow: {
                      rotate: 0,
                      stretch: 0,
                      depth: 600,
                      modifier: 1,
                      slideShadows: false
                  }
              });
            };
            if(app.isEdge()) {
              this.tablet_swiper = new Swiper(tablet_container, {
                  initialSlide: this._config.initial_slide,
                   slidesPerView: 'auto',
                   centeredSlides: true,
                  slideToClickedSlide: true,
                  grabCursor: true,
                  spaceBetween: 0
              });
            }
        },




        /**
         * _handleClassChange - handle one of mini-slides becoming active
         *
         */
        _handleClassChange: function () {
            var self = this;
            // if (!app.isMobile()) {
            //   this._elements.tablet_wrap.find(this._selectors.slide).css('width', Math.floor(0.75*this._slide_width));
            //   this._elements.tablet_wrap.find(this._selectors.current_slide).css('width', Math.floor(this._slide_width));
            // }
        },


        /**
         * _setSlidesHeight - resize text area, for tablet+ swiper calculate slide height.
         *
         */
        _setSlidesHeight: function () {
            var self = this;
            var $slide = this._elements.tablet_wrap.closest('.slide');
            var container_height = $slide.innerHeight();
            var text_top = $slide.find('.slide-text').position().top;
            var text_height = $slide.find('.slide-text').outerHeight();
            var text_indent = text_top + text_height +15;

            var titles_top = $slide.find('.slide-titles').position().top;
            var titles_height = $slide.find('.slide-titles').outerHeight();
            var titles_indent = titles_top + titles_height + 15;
            // var page_padding = parseInt($slide.css('padding-top'));

            var padding_block_height = (text_indent > titles_indent) ? text_indent : titles_indent;


            if(!app.isMobile()) {
              this._elements.padding.css('height', padding_block_height);
              // var free_space = container_height - padding_block_height;
              // this._elements.tablet_wrap.css('height', free_space);
              //
              // if (free_space <= $(window).innerWidth()) {
              //   this._slide_width = Math.floor(free_space * 0.8);
              // } else {
              //   this._slide_width = Math.floor($(window).innerWidth() * 0.8);
              // }


              // this._elements.tablet_wrap.find(this._selectors.slide).css('width', Math.floor(0.75*this._slide_width));
              //
              // var delta = (app.vars.windowWidth - this._slide_width)/5;
              // var space_between = 0.75*this._slide_width - delta;
              // if(space_between > 0 && space_between < 0.6 * this._slide_width) {
              //   this.tablet_swiper.params.spaceBetween = -(space_between);
              // } else if (space_between < 0){
              //   this.tablet_swiper.params.spaceBetween = 0;
              // } else {
              //   this.tablet_swiper.params.spaceBetween = -(0.6 * this._slide_width)
              // }
              // this.tablet_swiper.update(true);
            } else {
              this._elements.padding.css('height', 0);
            }
        },

        _setVideoWrapperWidth: function() {
          // var $container = this._elements.mobile_wrap.find(this._selectors.container);
          // var $video_wrappers = this._elements.mobile_wrap.find(this._selectors.video_wrapper);
      		// var new_width = Math.floor($(window).innerWidth() * 0.8);
      		// $container.css('height', new_width + 40); // plus paddings!
      		// $video_wrappers.css('width', new_width);
      	}
    };
})();
